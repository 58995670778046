<template>
  <div>
    <result-menu ref="resultMenu"></result-menu>
  </div>
</template>

<script>
import ResultMenu from "./ResultMenu.vue";

export default {
  components: {
    ResultMenu,
  },
  mounted() {
    const menus = this.$refs.resultMenu.menus;
    for (let element of menus) {
      if (this.$can("read", element.resource)) {
        this.$router.replace({
          name: element.routeName,
        });
        break;
      }
    }
  },
  setup() {},
};
</script>